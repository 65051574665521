import './src/styles/backgrounds.css'
import './src/styles/iframe.css'
import './src/styles/markdown-page.css'
import './src/styles/links.css'
import './src/styles/utilities.css'
import './src/styles/vendor/react-photo-gallery.css'
import './src/styles/vendor/tippy-theme.css'
// Important that global.css goes at the end so the tailwind
// utility classes can override those specified above if necessary
import './src/styles/global.css'

import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const bugsnagApiKey = () => {
	// the environment variable in netlify is not coming through for reasons unknown since updating to Gatsby v3.14.0,
	// so we're declaring the api key here and using it from process.env if it is available.
	let bugsnagApiKey = `342a111296a143135c1c32d2eec12637`
	if (process.env.BUGSNAG_API_KEY && process.env.BUGSNAG_API_KEY.length > 0) {
		bugsnagApiKey = process.env.BUGSNAG_API_KEY
	}

	return bugsnagApiKey
}

Bugsnag.start({
	apiKey: bugsnagApiKey(),
	plugins: [new BugsnagPluginReact()],
	releaseStage: process.env.CONTEXT || process.env.NODE_ENV,
	appVersion: process.env.DEPLOY_ID,
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

export const wrapRootElement = ({ element }) => (
	<ErrorBoundary>{element}</ErrorBoundary>
)

